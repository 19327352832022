import storage from "../../api/api";
import Vue from "vue";

export const state = {
  users: [],
  roles: [],
  permissions: []
};

export const getters = {
  users: state => state.users,
  roles: state => state.roles,
  permissions: state => state.permissions
};

export const actions = {
  pull({ commit }) {
    commit("flush");
    storage.getUsers().then(({ data }) => {
      data.forEach(function(item) {
        commit("push", item);
      });
    });
  },
  delete({ commit }, item) {
    storage
      .deleteUser(item.id)
      .then(() => {
        commit("delete", item);
        this._vm.$snotify.success("Видалено користувача");
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при видаленні користувача");
      });
  },
  async createUser({ commit }, payload) {
    await storage.createUser(payload).then(({ data }) => {
      commit("push", data);
      this._vm.$snotify.success("Додано користувача");
    });
  },
  async updateUser({ commit }, payload) {
    await storage.updateUser(payload.id, payload).then(({ data }) => {
      commit("update", data);
      this._vm.$snotify.success("Оновлено користувача");
    });
  },
  deleteRole({ commit }, item) {
    storage
      .deleteRole(item.id)
      .then(() => {
        commit("deleteRole", item);
        this._vm.$snotify.success("Видалено роль");
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при видаленні ролі");
      });
  },
  createRole({ commit }, payload) {
    storage
      .createRole(payload)
      .then(({ data }) => {
        commit("pushRole", data);
        this._vm.$snotify.success("Додано роль");
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при додаванні ролі");
      });
  },
  updateRole({ commit }, payload) {
    storage
      .updateRole(payload.id, payload)
      .then(({ data }) => {
        commit("updateRole", data);
        this._vm.$snotify.success("Оновлено роль");
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при оновленні ролі");
      });
  },
  pullRoles({ commit }) {
    commit("flushRoles");
    storage.getRoles().then(({ data }) => {
      data.forEach(function(item) {
        commit("pushRole", item);
      });
    });
  },
  pullPermissions({ commit }) {
    commit("flushPermissions");
    storage.getPermissions().then(({ data }) => {
      data.forEach(function(item) {
        commit("pushPermission", item);
      });
    });
  }
};

export const mutations = {
  flush: state => (state.users = []),
  push: (state, user) => state.users.push(user),
  update: (state, user) =>
    Vue.set(
      state.users,
      state.users.findIndex(item => item.id === user.id),
      user
    ),
  delete: (state, user) => state.users.splice(state.users.indexOf(user), 1),
  flushRoles: state => (state.roles = []),
  pushRole: (state, role) => state.roles.push(role),
  updateRole: (state, role) =>
    Vue.set(
      state.roles,
      state.roles.findIndex(item => item.id === role.id),
      role
    ),
  deleteRole: (state, role) => state.roles.splice(state.roles.indexOf(role), 1),
  flushPermissions: state => (state.permissions = []),
  pushPermission: (state, permission) => state.permissions.push(permission)
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
