import { axiosDocsInstance } from "@/api/docsApi";

export default {
  updateBeneficialOwner(id, form) {
    return axiosDocsInstance
      .put(`beneficial-owners/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteBeneficialOwner(id) {
    return axiosDocsInstance
      .delete(`beneficial-owners/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  updateOwner(id, form) {
    return axiosDocsInstance.put(`owners/${id}`, form).then(({ data }) => {
      return data;
    });
  },
  deleteOwner(id) {
    return axiosDocsInstance.delete(`owners/${id}`).then(({ data }) => {
      return data;
    });
  },
  updateParentCompany(id, form) {
    return axiosDocsInstance
      .put(`parent-companies/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteParentCompany(id) {
    return axiosDocsInstance
      .delete(`parent-companies/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  updateSeparateUnit(id, form) {
    return axiosDocsInstance
      .put(`separate-units/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteSeparateUnit(id) {
    return axiosDocsInstance.delete(`separate-units/${id}`).then(({ data }) => {
      return data;
    });
  },
  updateRepresentative(id, form) {
    return axiosDocsInstance
      .put(`representatives/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deleteRepresentative(id) {
    return axiosDocsInstance
      .delete(`representatives/${id}`)
      .then(({ data }) => {
        return data;
      });
  },
  updatePropertyCommander(id, form) {
    return axiosDocsInstance
      .put(`property-commanders/${id}`, form)
      .then(({ data }) => {
        return data;
      });
  },
  deletePropertyCommander(id) {
    return axiosDocsInstance
      .delete(`property-commanders/${id}`)
      .then(({ data }) => {
        return data;
      });
  }
};
