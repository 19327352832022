const Auth = Object.create({});

Auth.install = function(Vue, { store }) {
  Vue.prototype.$auth = new Vue({
    methods: {
      can: function(ability) {
        return (
          store.getters["auth/me"].permissions.filter(function(permission) {
            return permission.name === ability;
          }).length > 0
        );
      },
      has: function(role) {
        const me = store.getters["auth/me"];
        return me.role.name === role;
      }
    }
  });
};

export default Auth;
