import moment from "moment/moment";

export const amount = function(value) {
  return value
    ? Number.parseFloat(value)
        .toFixed(2)
        .replace(".", ",")
    : "";
};

export const date = function(value) {
  return value ? moment(value).format("DD.MM.YYYY") : null;
};

export const dateTime = function(value) {
  return value ? moment(value).format("DD.MM.YYYY, HH:mm:ss") : null;
};

export const boolToText = function(value) {
  if (value === null) {
    return null;
  }

  return value ? "Так" : "Ні";
};
