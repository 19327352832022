import { axiosDocsInstance } from "@/api/docsApi";

export default {
  getMe() {
    return axiosDocsInstance.get("users/me").then((response) => {
      return response.data.data;
    });
  },
  getRoles() {
    return axiosDocsInstance.get(`roles`).then((r) => {
      return r.data;
    });
  },
  getPermissions() {
    return axiosDocsInstance.get(`roles/permissions`).then((r) => {
      return r.data;
    });
  },
  getUsers() {
    return axiosDocsInstance.get("users").then((r) => {
      return r.data;
    });
  },
  getUsersFinancialModels() {
    return axiosDocsInstance.get("users/financial-models").then((response) => {
      return response.data;
    });
  },
  createUser(form) {
    return axiosDocsInstance.post("users", form).then((response) => {
      return response.data;
    });
  },
  updateUser(id, form) {
    return axiosDocsInstance.put(`users/${id}`, form).then((response) => {
      return response.data;
    });
  },
  deleteUser(id) {
    return axiosDocsInstance.delete(`users/${id}`).then((response) => {
      return response.data;
    });
  },
  createRole(form) {
    return axiosDocsInstance.post("roles", form).then((response) => {
      return response.data;
    });
  },
  updateRole(id, form) {
    return axiosDocsInstance.put(`roles/${id}`, form).then((response) => {
      return response.data;
    });
  },
  deleteRole(id) {
    return axiosDocsInstance.delete(`roles/${id}`).then((response) => {
      return response.data;
    });
  },
};
