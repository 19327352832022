import { axiosRepsInstance } from "@/api/repsApi";

export default {
  async createReport(route, params) {
    return axiosRepsInstance
      .post("reports/" + route, params)
      .then((response) => {
        return response;
      });
  },
  async sendFileReport(formData) {
    return axiosRepsInstance
      .post("reports/files", formData)
      .then((response) => {
        return response;
      });
  },
  async getUploadedFilesReports(params) {
    return axiosRepsInstance
      .get("reports/files", { params })
      .then((response) => {
        return response;
      });
  },
};
