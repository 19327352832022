import axios from "axios";
import { axiosAggsInstance } from "@/api/aggsApi";

const cancelToken = axios.CancelToken;
let cancelSource = cancelToken.source();
const refreshToken = () => {
  cancelSource = cancelToken.source();
};
const cancelAllRequests = () => {
  cancelSource.cancel();
};

export default {
  async getTotalSumByOperations(params) {
    return axiosAggsInstance.get("operations/get-total-sum", { params });
  },
  getOperationReceipt(operationId) {
    return axiosAggsInstance.get(`operations/receipt/${operationId}`);
  },
  getOperationList(params) {
    cancelAllRequests();
    refreshToken();
    return axiosAggsInstance.get("operations", {
      cancelToken: cancelSource.token,
      params
    });
  }
};
