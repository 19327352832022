import { axiosDocsInstance } from "@/api/docsApi";

export default {
  loginUser(params) {
    return axiosDocsInstance.post("login", params);
  },

  twoFaLoginUser(params) {
    return axiosDocsInstance.post("2fa", params);
  },

  registerUser(params) {
    return axiosDocsInstance.post("register", params);
  },

  logoutUser() {
    return axiosDocsInstance.get("logout");
  },

  resetExpiredPassword(params) {
    return axiosDocsInstance.post("reset-expired-password", params);
  },

  resetPassword(params) {
    return axiosDocsInstance.post("reset-password", params);
  },

  forgotPassword(params) {
    return axiosDocsInstance.post("forgot-password", params);
  }
};
