import axios from "axios";
import { axiosAggsInstance } from "@/api/aggsApi";
import { axiosRepsInstance } from "@/api/repsApi";

const cancelToken = axios.CancelToken;
let cancelSource = cancelToken.source();
const refreshToken = () => {
  cancelSource = cancelToken.source();
};
const cancelAllRequests = () => {
  cancelSource.cancel();
};

export default {
  getContractsMonitoring(params) {
    cancelAllRequests();
    refreshToken();
    return axiosAggsInstance.get("contracts-monitoring", {
      cancelToken: cancelSource.token,
      params,
    });
  },
  exportContractsMonitoring(params) {
    cancelAllRequests();
    refreshToken();
    return axiosRepsInstance.get("exports/monitoring/contracts", {
      cancelToken: cancelSource.token,
      params,
    });
  },
};
