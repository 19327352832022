import axios from "axios";
import { axiosRepsInstance } from "@/api/repsApi";

const cancelToken = axios.CancelToken;
let cancelSource = cancelToken.source();
const refreshToken = () => {
  cancelSource = cancelToken.source();
};
const cancelAllRequests = () => {
  cancelSource.cancel();
};

export default {
  exportCardsMonitoring(params) {
    cancelAllRequests();
    refreshToken();
    return axiosRepsInstance.get("exports/monitoring/cards", {
      cancelToken: cancelSource.token,
      params,
    });
  },
};
