import Vue from "vue";
import Vuex from "vuex";
import counterpart from "./modules/counterpart";
import counterparts from "./modules/counterparts";
import contract from "./modules/contract";
import contracts from "./modules/contracts";
import financialModel from "./modules/financialModel";
import financialModels from "./modules/financialModels";
import auth from "./modules/auth";
import user from "./modules/user";
import dictionary from "./modules/dictionary";
import tasks from "./modules/tasks";
import regulationsNbu from "./modules/regulationsNbu";

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context("./modules", false, /.*\.js$/);

// eslint-disable-next-line no-unused-vars
const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
  .reduce((modules, [name, module]) => {
    modules[name] = module;
    return modules;
  }, {});

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  strict: debug,
  modules: {
    contract,
    contracts,
    counterpart,
    counterparts,
    financialModel,
    financialModels,
    auth,
    user,
    dictionary,
    tasks,
    regulationsNbu,
  },
});
