<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hide-header
    centered
    id="expired_token_modal"
  >
    <div class="text-center">
      <b-icon icon="exclamation-circle" variant="warning" font-scale="5" />
      <p class="my-4">
        <b>Час терміну дії вашої сесії минув</b>
        <br />
        <br />
        Перезавантажте сторінку для повторного входу в систему
      </p>
      <b-button @click="reloadPage" class="btn-decorate">
        Перезавантажити сторінку
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ExpiredTokenModal",
  methods: {
    reloadPage() {
      window.location.reload();
    }
  }
};
</script>
<style lang="scss">
.btn-decorate {
  background-color: #6752b1 !important;
  border-color: #6752b1 !important;
  color: #ffffff !important;
  opacity: 1 !important;
  &:hover {
    background-color: #5d44b0;
    color: #ffffff;
  }
  &:focus {
    background-color: #5d44b0;
    color: #ffffff;
  }
}
#expired_token_modal + .modal-backdrop {
  opacity: 0.9 !important;
}
</style>
