import storage from "../../api/api";
import { getField } from "vuex-map-fields/src";

export const state = {
  items: []
};

export const getters = {
  getField,
  getAllFinancialModels: state => {
    return state.items;
  }
};

export const actions = {
  async add({ commit }, item) {
    await storage.createFinancialModel(item).then(({ data }) => {
      commit("push", data);
    });
  },
  async pull({ commit }) {
    commit("flush");
    await storage.getUsersFinancialModels().then(({ data }) => {
      data.forEach(function(item) {
        commit("push", item);
      });
    });
  },
  async delete({ commit }, item) {
    await storage
      .deleteFinancialModel(item.id)
      .then(() => {
        commit("delete", item);
        this._vm.$snotify.success("Видалено");
      })
      .catch(error => {
        this._vm.$snotify.error(error.response.data.message);
      });
  }
};

export const mutations = {
  flush: state => (state.items = []),
  push: (state, item) => state.items.push(item),
  delete: (state, item) => state.items.splice(state.items.indexOf(item), 1)
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
