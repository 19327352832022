export default class SharedWorkerHelper {
  constructor() {
    // Check for browser Shared Web Worker support before initialization
    if (window.SharedWorker) {
      this.worker = new SharedWorker("/workers/sharedWorker.js");
      this.events = {};
      this.worker.port.start();

      this.worker.port.addEventListener(
        "message",
        e => this.callEventCallback(e.data),
        false
      );

      this.worker.port.addEventListener(
        "error",
        () => this.callEventCallback("error"),
        false
      );
    }
  }

  trigger(eventName, data) {
    // Trigger an event message towards the worker script
    this.worker.port.postMessage({
      eventName,
      data
    });
  }

  terminate(data) {
    // Terminates the worker thread with its events and timer
    this.trigger("clearUuidTimer", data);
    this.worker.port.postMessage({
      type: "cmd",
      action: "die"
    });
  }

  on(eventName, callback) {
    // Record events and callbacks to listen on
    this.events[eventName] = callback;
  }

  callEventCallback({ event, data }) {
    // Call external callback based on event listened
    if (Object.prototype.hasOwnProperty.call(this.events, event)) {
      this.events[event].call(null, data);
    }
  }
}
