import { axiosRepsInstance } from "@/api/repsApi";

export default {
  async exportOperations(route, filters) {
    return axiosRepsInstance
      .post("exports/operations/" + route, null, { params: filters })
      .then(response => {
        return response;
      });
  }
};
