import axios from "axios";
import { axiosAggsInstance } from "@/api/aggsApi";

const cancelToken = axios.CancelToken;
let cancelSource = cancelToken.source();
const refreshToken = () => {
  cancelSource = cancelToken.source();
};
const cancelAllRequests = () => {
  cancelSource.cancel();
};

export default {
  getCardsMonitoring(params) {
    cancelAllRequests();
    refreshToken();
    return axiosAggsInstance.get("cards-monitoring", {
      cancelToken: cancelSource.token,
      params,
    });
  },
  getTotalSum(params) {
    cancelAllRequests();
    refreshToken();
    return axiosAggsInstance.get("cards-monitoring/get-total-sum", {
      cancelToken: cancelSource.token,
      params,
    });
  },
};
