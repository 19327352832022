import { axiosDocsInstance } from "@/api/docsApi";
import store from "@/store";
import axios from "axios";

const cancelToken = axios.CancelToken;
let cancelSource = cancelToken.source();
const cancelAllRequests = () => {
  cancelSource.cancel();
};
const refreshToken = () => {
  cancelSource = cancelToken.source();
};

export default {
  getContractItems(params = {}) {
    cancelAllRequests();
    refreshToken();
    return axiosDocsInstance
      .get("contracts", {
        cancelToken: cancelSource.token,
        params
      })
      .then(response => {
        return response.data;
      });
  },
  getContractsCounterparts(config = {}) {
    return axiosDocsInstance
      .get("contracts/counterparts", config)
      .then(response => {
        return response.data;
      });
  },
  exportContractData() {
    return axiosDocsInstance.get("contracts/export", {
      params: store.getters["contracts/getFilters"]
    });
  },
  getContractById(id) {
    return axiosDocsInstance.get("contracts/" + id).then(response => {
      return response.data;
    });
  },
  updateContract(id, item) {
    return axiosDocsInstance.put(`contracts/${id}`, item).then(({ data }) => {
      return data;
    });
  },
  updateContractPaymentDirections(id, item) {
    return axiosDocsInstance
      .post(`contracts/${id}/payment-directions`, item)
      .then(({ data }) => {
        return data;
      });
  },
  removeContract(id) {
    return axiosDocsInstance.delete("contracts/" + id).then(response => {
      return response.data;
    });
  },
  restoreContract(id) {
    return axiosDocsInstance
      .post(`contracts/${id}/restore`)
      .then(({ data }) => {
        return data;
      });
  }
};
