import { axiosDocsInstance } from "@/api/docsApi";

export default {
  getTasks(config = {}) {
    return axiosDocsInstance.get("tasks", config).then(response => {
      return response.data;
    });
  },
  getTaskCounterparts(config = {}) {
    return axiosDocsInstance
      .get(`tasks/${config.params.id}/counterparts`, config)
      .then(response => {
        return response.data;
      });
  },
  updateTaskWithCounterpartItems(config = {}, taskId) {
    return axiosDocsInstance
      .post(`tasks/${taskId}/counterparts`, null, config)
      .then(response => {
        return response.data;
      });
  },
  storeTask(config = {}, taskType) {
    return axiosDocsInstance
      .post(`tasks/${taskType}`, null, config)
      .then(response => {
        return response.data;
      });
  }
};
