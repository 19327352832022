import storage from "../../api/api";
import { getField, updateField } from "vuex-map-fields/src";

export const state = {
  itemTasks: [],
  itemCounterparts: [],
  itemTaskCounterparts: [],
  pageTasks: 1,
  countTasks: 20,
  totalTasks: 0,
  pageCounterparts: 1,
  countCounterparts: 50,
  totalCounterparts: 0,
  pageTaskCounterparts: 1,
  countTaskCounterparts: 50,
  totalTaskCounterparts: 0,
  filterTaskCounterparts: {},
  filterCounterpart: {}
};

export const getters = {
  getField,
  getAllTasks: state => {
    return state.itemTasks;
  },
  totalTaskItems: state => {
    return state.totalTasks;
  },
  getAllTaskCounterparts: state => {
    return state.itemTaskCounterparts;
  },
  totalTaskCounterpartItems: state => {
    return state.totalTaskCounterparts;
  },
  getAllCounterparts: state => {
    return state.itemCounterparts;
  },
  totalCounterpartItems: state => {
    return state.totalCounterparts;
  }
};

export const actions = {
  setTasks({ commit }) {
    let config = {
      params: {
        page: state.pageTasks,
        count: state.countTasks
      }
    };
    return storage
      .getTasks(config)
      .then(response => commit("setTasks", response));
  },
  setTaskCounterparts({ commit }, payload) {
    let config = {
      params: {
        page: state.pageTaskCounterparts,
        count: state.countTaskCounterparts,
        id: payload
      }
    };
    return storage
      .getTaskCounterparts(config)
      .then(response => commit("setTaskCounterparts", response));
  },

  async counterpartsUpdate({ commit }, payload) {
    let config = {
      params: {
        ...state.filterTaskCounterparts
      }
    };

    await storage
      .updateTaskWithCounterpartItems(config, payload)
      .then(response => {
        commit("updateTask", response);
        this._vm.$snotify.success("Оновлено контрагентів");
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при оновленні контрагентів");
      });
  },
  async setCounterparts({ commit }) {
    let config = {
      params: {
        page: state.pageCounterparts,
        count: state.countCounterparts,
        ...state.filterCounterpart
      }
    };
    await storage
      .getCounterpartItems(config.params)
      .then(response => commit("setCounterparts", response));
  },
  // eslint-disable-next-line no-unused-vars
  createTask({ commit }, payload) {
    let config = {
      params: {
        ...state.filterCounterpart
      }
    };

    return storage
      .storeTask(config, payload)
      .then(() => {
        this._vm.$snotify.success("Завдання створено");
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при створенні завдання");
      });
  },
  clearCounterparts({ commit }) {
    commit("setNullCounterparts");
  }
};

export const mutations = {
  updateField,
  setTasks(state, response) {
    state.itemTasks = response.data.map(item => {
      return Object.assign(item);
    });
    state.totalTasks = response.meta.total;
  },
  setTaskCounterparts(state, response) {
    state.itemTaskCounterparts = response.data.map(item => {
      return Object.assign(item);
    });
    state.totalTaskCounterparts = response.meta.total;
  },
  setTaskCounterpartsFilter(state, filterObj) {
    state.filterTaskCounterparts = {};
    for (let key in filterObj) {
      if (filterObj[key].value.length === 0) {
        continue;
      }
      state.filterTaskCounterparts[
        "filter[" + filterObj[key].name + "@" + filterObj[key].type + "]"
      ] = filterObj[key].value;
    }
  },
  setCounterpartsFilter(state, filterObj) {
    state.filterCounterpart = {};
    for (let key in filterObj) {
      if (filterObj[key].value.length === 0) {
        continue;
      }
      state.filterCounterpart[
        "filter[" + filterObj[key].name + "@" + filterObj[key].type + "]"
      ] = filterObj[key].value;
    }
  },
  updateTask(state, response) {
    Object.assign(
      state.itemTasks.find(t => t.id === response.data[0].id),
      response.data[0]
    );
  },
  setCounterparts(state, response) {
    state.itemCounterparts = response.data.map(item => {
      return Object.assign(item);
    });
    state.totalCounterparts = response.meta.total;
  },
  setNullCounterparts(state) {
    state.itemCounterparts = [];
    state.totalCounterparts = 0;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
