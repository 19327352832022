import { axiosDocsInstance } from "@/api/docsApi";

export default {
  addFiles(form, id_counterpart) {
    return axiosDocsInstance
      .post(`file/${id_counterpart}`, form)
      .then(response => {
        return response.data;
      });
  },
  downloadFiles(id_counterpart, name) {
    return axiosDocsInstance
      .get(`file/${id_counterpart}`, {
        params: { name: name },
        responseType: "blob"
      })
      .then(response => {
        return response;
      });
  },
  deleteFiles(id_counterpart, name) {
    return axiosDocsInstance
      .delete(`file/${id_counterpart}`, {
        params: { name: name }
      })
      .then(response => {
        return response.data;
      });
  },
  getFiles(id_counterpart) {
    return axiosDocsInstance.get(`file/${id_counterpart}`).then(response => {
      return response.data;
    });
  }
};
