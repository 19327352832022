import storage from "../../api/api";
import Vue from "vue";

export const state = {
  financialModel: {},
};

export const getters = {
  get: (state) => state.financialModel,
  getAccounts: (state) => state.financialModel?.accounts || [],
  getLicenses: (state) => state.financialModel?.licenses || [],
  getStamps: (state) => state.financialModel?.stamps || [],
  getF2hxs: (state) => state.financialModel?.f2hxs || [],
  getIseis: (state) => state.financialModel?.iseis || [],
  getExternalTenants: (state) => state.financialModel?.externalTenants || [],
};

export const actions = {
  async pull({ commit }, id) {
    return await storage.getFinancialModelById(id).then(({ data }) => {
      commit("push", data);
    });
  },
  async pullF2hxs({ commit }, id) {
    return await storage.getF2hxsByFinancialModelId(id).then(({ data }) => {
      commit("pushF2hxs", data);
    });
  },
  async pullIseis({ commit }, id) {
    return await storage.getIseis(id).then(({ data }) => {
      commit("pushIseis", data);
    });
  },
  async pullExternalTenants({ commit }, id) {
    return await storage.getExternalTenants(id).then(({ data }) => {
      commit("pushExternalTenants", data);
    });
  },
  update({ commit }, item) {
    return new Promise((resolve, reject) => {
      storage
        .updateFinancialModel(item.id, item)
        .then(({ data }) => {
          commit("push", data);
          this._vm.$snotify.success("Збережено");
          resolve();
        })
        .catch(({ response }) => {
          this._vm.$snotify.error("Не збережено");
          reject(response);
        });
    });
  },
  async flush({ commit }) {
    await commit("clear");
  },
  async createAccount({ commit }, payload) {
    await storage
      .createAccount(payload.financial_model_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано рахунок");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при додаванні рахунку");
      });
  },
  async updateAccount({ commit }, form) {
    await storage
      .updateAccount(form.id, form)
      .then(({ data }) => {
        this._vm.$snotify.success("Оновлено дані рахунку");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при оновленні данних рахунку");
      });
  },
  async deleteAccount({ commit }, form) {
    await storage
      .deleteAccount(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено рахунок");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при видаленні рахунку");
      });
  },
  async uploadStamp({ commit }, payload) {
    await storage
      .uploadStamp(payload[0], payload[1])
      .then(({ data }) => {
        this._vm.$snotify.success("Печатку успішно збережено");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error(
          "Виникла помилка при додаванні печатки. Зображення має бути формату .jpg або .jpeg, бути не більше 300 Кб, ширина від 300 до 2000 пікселів та висота від 200 до 1000 пікселів."
        );
      });
  },
  async getStampById(context, id) {
    return await storage.getStamp(id);
  },
  async createLicense({ commit }, payload) {
    await storage
      .createLicense(payload.financial_model_id, payload)
      .then(({ data }) => {
        this._vm.$snotify.success("Додано ліцензію");
        commit("push", data);
      });
  },
  async updateLicense({ commit }, form) {
    await storage.updateLicense(form.id, form).then(({ data }) => {
      this._vm.$snotify.success("Оновлено дані ліцензії");
      commit("push", data);
    });
  },
  async deleteLicense({ commit }, form) {
    await storage
      .deleteLicense(form.id)
      .then(({ data }) => {
        this._vm.$snotify.success("Видалено ліцензію");
        commit("push", data);
      })
      .catch(() => {
        this._vm.$snotify.error("Виникла помилка при видаленні лізензії");
      });
  },
  async createIsei({ commit }, payload) {
    return await storage
      .createIsei(payload.get("financial_model_id"), payload)
      .then(({ data }) => {
        commit("push", data);
      });
  },
  syncNbuCredentials({ commit }, payload) {
    return new Promise((resolve, reject) => {
      storage
        .syncNbuCredentials(payload.financial_model_id, payload)
        .then(({ data }) => {
          commit("push", data);
          resolve();
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async storeExternalTenants({ commit }, payload) {
    return await storage
      .storeExternalTenants(payload.financial_model_id, payload)
      .then(({ data }) => {
        commit("push", data);
      });
  },
};

export const mutations = {
  push: (state, item) =>
    Object.entries(item).forEach(([key, value]) =>
      Vue.set(state.financialModel, key, value)
    ),
  pushF2hxs: (state, items) => Vue.set(state.financialModel, "f2hxs", items),
  pushIseis: (state, items) => Vue.set(state.financialModel, "iseis", items),
  pushExternalTenants: (state, items) =>
    Vue.set(state.financialModel, "externalTenants", items),
  clear: (state) => Vue.set(state, "financialModel", {}),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
