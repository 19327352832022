import storage from "@/api/api";
import Vue from "vue";

export const state = {
  contract: {}
};

export const getters = {
  get: state => state.contract,
  getPaymentDirections: state =>
    state.contract ? state.contract.payment_directions : []
};

export const actions = {
  async pull({ commit }, id) {
    return await storage.getContractById(id).then(({ data }) => {
      commit("push", data);
    });
  },
  async flush({ commit }) {
    await commit("clear");
  },
  async update({ commit }, item) {
    await storage
      .updateContract(item.id, item)
      .then(({ data }) => {
        commit("push", data);
        this._vm.$snotify.success("Збережено");
      })
      .catch(() => {
        this._vm.$snotify.error("Не збережено");
      });
  },
  updatePaymentDirections({ commit }, payload) {
    return storage
      .updateContractPaymentDirections(payload.id, payload.form)
      .then(({ data }) => {
        commit("push", data);
        this._vm.$snotify.success("Збережено");
      })
      .catch(({ response }) => {
        this._vm.$snotify.error("Не збережено");
        return response.data;
      });
  }
};

export const mutations = {
  push: (state, item) =>
    Object.entries(item).forEach(([key, value]) =>
      Vue.set(state.contract, key, value)
    ),
  updateField: (state, { key, value }) => Vue.set(state.contract, key, value),
  clear: state => Vue.set(state, "contract", {})
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
