import storage from "../../api/api";
import moment from "moment/moment";

export const state = {
  items: [],
  meta: {
    total: 0,
    //totalSum: 0,
    page: 1,
    per_page: 20,
    sort_by: "",
  },
  filter: {},
  errors: {},
  submitting: false,
};

export const getters = {
  getFilters: (state) => {
    return {
      ...state.filter,
    };
  },
  getRegulationsNbu: (state) => {
    return state.items;
  },
  getMeta: (state) => {
    return state.meta;
  },
  getErrors: (state) => {
    return state.errors;
  },
  getSubmitting: (state) => {
    return state.submitting;
  },
};

export const actions = {
  getRegulationsNbu({ commit }) {
    let config = {
      params: {
        page: state.meta.page,
        per_page: state.meta.per_page,
        sort_by: state.meta.sort_by,
        ...state.filter,
      },
    };
    commit("clearErrors");
    commit("setSubmitting", true);

    return storage
      .getRegulationsNbu(config.params)
      .then((response) => {
        commit("setRegulationsNbu", response.data);
      })
      .catch(({ response }) => {
        commit("setErrors", response);
      })
      .finally(() => commit("setSubmitting", false));
  },
};

export const mutations = {
  setRegulationsNbu(state, response) {
    state.items = response.data.map((item) => {
      return Object.assign(item);
    });
    state.meta.total = response.meta.total;
    state.meta.totalSum = response.meta.totalSum;
  },
  setFilter(state, filterObj) {
    state.filter = {};
    for (let key in filterObj) {
      if (filterObj[key].value.length === 0) {
        continue;
      }

      state.filter[
        "filter[" + filterObj[key].name + "@" + filterObj[key].type + "]"
      ] =
        key === "dateFrom" || key === "dateTo"
          ? moment(filterObj[key].value).format("YYYY-MM-DD")
          : filterObj[key].value;
    }
  },
  setPage(state, page) {
    state.meta.page = page;
  },
  setSortBy(state, sortBy) {
    state.meta.sort_by = sortBy;
  },
  setPerPage(state, perPage) {
    state.meta.per_page = perPage;
  },
  clearErrors(state) {
    state.errors = {};
  },
  setErrors(state, response) {
    state.errors = response.data;
  },
  setSubmitting(state, value) {
    state.submitting = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
