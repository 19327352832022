// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "core-js/es6/promise";
import "core-js/es6/string";
import "core-js/es7/array";
import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App";
import router from "./router";
import store from "./store";
import Snotify, { SnotifyPosition } from "vue-snotify";
import datePicker from "vue-bootstrap-datetimepicker";
import Auth from "./plugins/auth";
import { amount, boolToText, date, dateTime } from "@/filters";
import stringConstants from "./string-constants";

import {
  extend,
  localize,
  ValidationProvider,
  ValidationObserver
} from "vee-validate";
import {
  required,
  email,
  max,
  min,
  regex,
  digits,
  length,
  max_value,
  min_value,
  confirmed,
  double
} from "vee-validate/dist/rules";
import uk from "vee-validate/dist/locale/uk.json";

uk.messages.decimal = function(field, decSigns) {
  const signs = decSigns.toString() || "0";
  return `Поле ${field} повинно бути числовим та може містити ${signs}
        ${
          signs === "0" || signs > 4
            ? "знаків"
            : signs === "1"
            ? "знак"
            : "знаки"
        } після крапки.`;
};
localize("uk", uk);
extend("required", required);
extend("email", email);
extend("max", max);
extend("min", min);
extend("regex", regex);
extend("digits", digits);
extend("length", length);
extend("max_value", max_value);
extend("min_value", min_value);
extend("confirmed", confirmed);
extend("double", double);
extend("codeRegex", {
  validate(value, regex) {
    return new RegExp(regex[0]).test(value);
  },
  message: (fieldName, regex) => {
    if (regex[0] === "(^[\\d]{8}$)") {
      return "ЄДРПОУ має містити 8 цифр";
    }
    if (regex[0] === "(^[\\d]{10}$)") {
      return "РНОКПП має містити 10 цифр";
    }
    return "Код має містити від 1 до 21 символу (літери, цифри або тире)";
  },
});
extend("password", {
  validate: value => {
    return value.match(/^.*(?=.{8,})(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\\x]).*$/);
  },
  message:
    "Поле пароль повинно містити заголовні літери, цифри, спеціальні символи та бути не менше 8 символів."
});
extend("selected_financial_models_report_values", {
  validate: (value, { report }) => {
    if (report === null) {
      return true;
    }

    return !(report !== "f4ex" && value.length > 1);
  },
  params: ["report"],
  message: (fieldName, placeholders) => {
    return `Поле ${fieldName} має формуватись для звіту ${placeholders.report} лише по 1 профілю СПФМ`;
  }
});

Vue.filter("amount", amount);
Vue.filter("date", date);
Vue.filter("dateTime", dateTime);
Vue.filter("boolToText", boolToText);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

const snotifyOptions = {
  toast: {
    position: SnotifyPosition.leftTop,
    showProgressBar: false
  }
};

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Snotify, snotifyOptions);
Vue.use(datePicker);
Vue.use(Auth, { store });
Vue.use(stringConstants);
if (store.getters["auth/isAuth"] && !store.getters["auth/me"]) {
  store.dispatch("auth/getMe").then(() => {
    /* eslint-disable no-new */
    new Vue({
      el: "#app",
      store,
      router,
      template: "<App/>",
      components: {
        App
      }
    });
  });
} else {
  /* eslint-disable no-new */
  new Vue({
    el: "#app",
    store,
    router,
    template: "<App/>",
    components: {
      App
    }
  });
}
