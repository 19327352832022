import axios from "axios";
import NProgress from "nprogress";
import store from "@/store";
import { axiosDocsInstance } from "@/api/docsApi";
import reports from "@/api/reps/reports";
import operations from "@/api/reps/operations";
import declinedPayments from "@/api/reps/declinedPayments";
import regulationsNbu from "@/api/reps/regulationsNbu";
import cardsMonitoring from "@/api/reps/cardsMonitoring";
import counterpartsMonitoring from "@/api/reps/counterpartsMonitoring";
import counterparts from "@/api/reps/counterparts";

export const axiosRepsInstance = axios.create({
  baseURL: process.env.VUE_APP_REPS_API_URL ?? "/api-reps/v1/"
});

let recreateToken = false;

axiosRepsInstance.interceptors.request.use(
  config => {
    NProgress.start();
    let tokenLocalStorage = window.localStorage.getItem("token");

    if (
      tokenLocalStorage &&
      tokenLocalStorage !== "null" &&
      window.location.pathname === "/login"
    ) {
      window.location.href = "/";
    }

    if (tokenLocalStorage && tokenLocalStorage !== "null" && tokenLocalStorage !== "undefined") {
      config.headers["Authorization"] = `Bearer ${tokenLocalStorage}`;

      const tokenTime = new Date(
        JSON.parse(window.atob(tokenLocalStorage.split(".")[1])).exp * 1000
      );
      const diffTime = (tokenTime - new Date()) / 1000 / 60;

      if (
        diffTime < 15 &&
        diffTime > 0 &&
        recreateToken === false &&
        config.url !== "logout"
      ) {
        recreateToken = true;

        axiosDocsInstance.get("recreate-bearer-token").then(response => {
          recreateToken = false;
          config.headers["Authorization"] = `Bearer ${response.data.success}`;
          store.dispatch("auth/saveToken", {
            token: response.data.success
          });
        });
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axiosRepsInstance.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  error => {
    NProgress.done(true);
    if (error.response && error.response.status === 401) {
      store.dispatch("auth/saveToken", { token: null }).then(() => {
        location.reload();
      });
    }
    return Promise.reject(error);
  }
);

export default {
  ...reports,
  ...operations,
  ...counterparts,
  ...declinedPayments,
  ...regulationsNbu,
  ...cardsMonitoring,
  ...counterpartsMonitoring
};
