import storage from "../../api/api";

// state
export const state = {
  token: localStorage.getItem("token"),
  me: null,
  meFinancialModels: null,
};

// mutations
export const mutations = {
  saveToken(state, { token }) {
    state.token = token;
    localStorage.token = token;
  },
  clearToken(state) {
    localStorage.removeItem("token");
    state.token = null;
  },
  setMe(state, me) {
    state.me = me;
    state.meFinancialModels = me ? me.financial_models : [];
  },
};

// actions
export const actions = {
  async getMe({ commit }) {
    commit("setMe", await storage.getMe());
  },
  async saveToken({ commit }, payload) {
    await commit("saveToken", payload);
  },
  clearToken({ commit }) {
    commit("clearToken");
  },
};

// getters
export const getters = {
  isAuth: (state) => state.token !== "null" && state.token !== null,
  authToken: (state) => state.token,
  me: (state) => state.me,
  meFinancialModels: (state) => state.meFinancialModels,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
