import * as allStrings from "./constants";

const stringConstants = {
  ...allStrings
};

stringConstants.install = function(Vue) {
  Vue.prototype.$stringConstants = key => {
    return stringConstants[key];
  };
};

export default stringConstants;
