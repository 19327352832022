import axios from "axios";
import { axiosAggsInstance } from "@/api/aggsApi";

const cancelToken = axios.CancelToken;
let cancelSource = cancelToken.source();
const refreshToken = () => {
  cancelSource = cancelToken.source();
};
const cancelAllRequests = () => {
  cancelSource.cancel();
};

export default {
  async getDeclinedPaymentList(params) {
    cancelAllRequests();
    refreshToken();
    return axiosAggsInstance.get("declined-payments", {
      cancelToken: cancelToken.source().token,
      params,
    });
  },
  getPaymentUrl() {
    return axiosAggsInstance
      .get("declined-payments/make-test-payment")
      .then((response) => {
        return response.data;
      });
  },
};
