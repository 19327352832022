// типи контрагентів
export const LEGAL_PERSON_TYPE_VALUE = "legal-person";
export const GOVERNMENT_UNIT_TYPE_VALUE = "government-unit";
export const INDIVIDUAL_ENTREPRENEUR_TYPE_VALUE = "individual-entrepreneur";
export const SEPARATE_UNIT_TYPE_VALUE = "separate-unit";
export const INDIVIDUAL_TYPE_VALUE = "individual";
export const INDIVIDUAL_NON_RESIDENT_TYPE_VALUE = "individual-non-resident";
export const LEGAL_NON_RESIDENT_TYPE_VALUE = "legal-non-resident";
// типи контрактів
export const CONTRACT_INCOMING_TYPE_VALUE = "type1";
export const CONTRACT_OUTCOMING_TYPE_VALUE = "type2";
export const CONTRACT_MIXED_TYPE_VALUE = "type3";
export const CONTRACT_PAYSYSTEM_TYPE_VALUE = "type4";
// типи ідентифікації контрагента
export const IDENTIFICATION_VERIFICATION_COMPLETED_VALUE = "0";
export const IDENTIFICATION_INCOMPLETE_VALUE = "1";
export const NEEDS_CLARIFICATION_INFORMATION_VALUE = "2";
export const NO_BUSINESS_RELATIONSHIP_VALUE = "3";
// типи рівнів ризику
export const EXTREMELY_HIGH_VALUE = "extremelyHight";
export const HIGH_VALUE = "hight";
export const MIDDLE_VALUE = "middle";
export const LOW_VALUE = "low";
// базові ролі
export const ROLE_GUEST = "guest";
export const ROLE_OWNER = "owner";
export const ROLE_INSPECTOR = "inspector";
export const ROLE_EMPLOYEE = "employee";
// дозволи
export const PERMISSION_VIEW_COUNTERPART = "view counterpart";
export const PERMISSION_CREATE_COUNTERPART = "create counterpart";
export const PERMISSION_VIEW_CONTRACT = "view contract";
export const PERMISSION_CREATE_CONTRACT = "create contract";
export const PERMISSION_VIEW_FINANCIAL_MODEL = "view financial model";
export const PERMISSION_CREATE_FINANCIAL_MODEL = "create financial model";
export const PERMISSION_SET_FINANCIAL_MODEL_NBU_CREDENTIALS =
  "set financial model nbu credentials";
export const PERMISSION_VIEW_USER = "view user";
export const PERMISSION_CREATE_USER = "create user";
export const PERMISSION_VIEW_OPERATION = "view operation";
export const PERMISSION_VIEW_OPERATION_ID = "view operation id";
export const PERMISSION_VIEW_OPERATION_FINANCIAL_MODEL_ID =
  "view operation financial model id";
export const PERMISSION_VIEW_OPERATION_PAYSYSTEM_ID =
  "view operation paysystem id";
export const PERMISSION_VIEW_OPERATION_PAYSYSTEM_NAME =
  "view operation paysystem name";
export const PERMISSION_VIEW_OPERATION_TERMINAL_ID =
  "view operation terminal id";
export const PERMISSION_VIEW_OPERATION_TERMINAL_NAME =
  "view operation terminal name";
export const PERMISSION_VIEW_OPERATION_AUTH_CODE = "view operation auth code";
export const PERMISSION_VIEW_OPERATION_REGISTRY_REFERENCE_NUMBER =
  "view operation registry reference number";
export const PERMISSION_VIEW_OPERATION_MCC = "view operation mcc";
export const PERMISSION_VIEW_OPERATION_MERCHANT_ID =
  "view operation merchant id";
export const PERMISSION_VIEW_OPERATION_INITIATOR_IP =
  "view operation initiator ip";
export const PERMISSION_VIEW_OPERATION_INITIATOR_EMAIL =
  "view operation initiator email";
export const PERMISSION_VIEW_OPERATION_INITIATOR_ACCOUNT_NUMBER =
  "view operation initiator account number";
export const PERMISSION_VIEW_OPERATION_ORDER_DESCRIPTION =
  "view operation order description";
export const PERMISSION_VIEW_OPERATION_BANK_TERMINAL_ID =
  "view bank terminal id";
export const PERMISSION_VIEW_OPERATION_BANK_TERMINAL_NAME =
  "view bank terminal name";
export const PERMISSION_CREATE_OPERATION = "create operation";
export const PERMISSION_EXPORT_OPERATION = "export operation";
export const PERMISSION_VIEW_DECLINED_PAYMENT = "view declined payment";
export const PERMISSION_CREATE_DECLINED_PAYMENT = "create declined payment";
export const PERMISSION_VIEW_MONITORING = "view monitoring";
export const PERMISSION_EXPORT_MONITORING = "export monitoring";
export const PERMISSION_CREATE_REPORT = "create report";
export const PERMISSION_VIEW_REPORT = "view report";
export const PERMISSION_SEND_REPORT_TO_NBU = "send report to nbu";
export const PERMISSION_CREATE_TASK = "create task";
export const PERMISSION_VIEW_TASK = "view task";
export const PERMISSION_VIEW_COUNTERPART_HISTORY = "view counterpart history";
export const PERMISSION_PRINT_COUNTERPART = "print counterpart";
export const PENDING_STATE = "pending";
export const PROGRESS_STATE = "progress";
export const DONE_STATE = "done";
export const ERROR_STATE = "error";
export const NEED_TO_ACTION_STATE = "needToAction";
export const APPROVED_STATE = "approved";
export const CANCELED_STATE = "canceled";
export const PASSPORT_JOB_VALUE = "passport";
export const PASSPORT_CARD_ID_JOB_VALUE = "passportCardId";
export const LEGAL_SANCTION_JOB_VALUE = "legalSanction";
export const INDIVIDUAL_SANCTION_JOB_VALUE = "individualSanction";
export const TERRORIST_JOB_VALUE = "terrorist";
export const PUBLIC_PERSON_JOB_VALUE = "publicPerson";
export const COUNTRY_AGGRESSOR_JOB_VALUE = "countryAggressor";
export const FILLING_COUNTERPART_JOB_VALUE = "fillingCounterpart";
export const QUESTION_WITH_REQUIRED_NOTE = "510";
export const ANSWER_WITH_REQUIRED_NOTE = "501";
export const SPECIAL_DESTINATION_CODES = ["12", "17", "27", "99"];
export const TASK_PUBLIC_PERSON_JOB_VALUE = "taskPublicPerson";
export const TASK_SANCTION_JOB_VALUE = "taskSanction";
export const REGEX_TEXT_NAME =
  "^([\\d\\sйцукенгшщзхїґфивапролджєячсмітьбюЙЦУКЕНГШЩЗХЇҐФИВАПРОЛДЖЄЯЧСМІТЬБЮ\"№()+.,'-]+|[\\d\\sA-z\"№()+.,'-]+)$";
export const REGEX_TEXT_FIO =
  "^([\\sйцукенгшщзхїґфивапролджєячсмітьбюЙЦУКЕНГШЩЗХЇҐФИВАПРОЛДЖЄЯЧСМІТЬБЮ()'-]+|[\\sA-z()'-]+)$";
export const REGEX_UUID =
  "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$";
export const CURRENCY_LIST = ["UAH", "USD", "EUR", "PLN", "CZK"];
export const CODE_UKRAINE = "804";
export const LEGAL_ENTITY = "legal-entity";
export const NATURAL_PERSON = "natural-person";
