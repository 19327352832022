import { axiosDocsInstance } from "@/api/docsApi";

export default {
  updateCrimeRisk(id, form) {
    return axiosDocsInstance.put(`crime-risks/${id}`, form).then(({ data }) => {
      return data;
    });
  },
  deleteCrimeRisk(id) {
    return axiosDocsInstance.delete(`crime-risks/${id}`).then(({ data }) => {
      return data;
    });
  },
  updateCounterpartLicense(id, form) {
    return axiosDocsInstance.put(`licenses/${id}`, form).then(({ data }) => {
      return data;
    });
  },
  deleteCounterpartLicense(id) {
    return axiosDocsInstance.delete(`licenses/${id}`).then(({ data }) => {
      return data;
    });
  }
};
